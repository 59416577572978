import React from "react";
import { useHistory } from "react-router-dom";

interface DataTableProps {
  header: { title: string; slug: string }[];
  data: any[];
}

function DataTable({ header, data }: DataTableProps) {
  let history = useHistory();

  const headerSlugs: string[] = header.map((item) => {
    return item.slug;
  });

  function getTableRowData({ item, slug }: any): any {
    return item[slug];
  }

  function goToRoute(item: any): any {
    if (item.link) {
      history.push(item.link);
    }
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto select-none sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className="min-w-full">
              <thead className="text-white bg-blue-900">
                <tr>
                  {header.map((item, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="px-6 py-4 text-sm font-medium text-left"
                    >
                      {item.title}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y-1">
                {data.map((item, index) => (
                  <tr
                    key={index}
                    className={`transition duration-300 ease-in-out bg-white cursor-pointer hover:bg-gray-200 ${
                      index % 2 === 0 ? "bg-gray-100" : ""
                    }`}
                    onClick={(e) => goToRoute(item)}
                  >
                    {headerSlugs.map((slug, headerIndex) => (
                      <td
                        key={headerIndex}
                        className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap"
                      >
                        {getTableRowData({ item: item, slug: slug })}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataTable;
