import React from "react";
import { createTableBodyRowData } from "../../services/wbr";

function UnderChartTable({ deck }: { deck: any }) {
  return (
    <table>
      <thead>
        <tr>
          {deck.table?.tableHeader?.map((item: any, index: number) => (
            <th className="w-24 text-sm font-medium" key={index}>
              <span className="underline">{item}</span>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {deck.table?.tableBody?.map((rowData: any, rowIndex: number) => {
          return (
            <tr key={rowIndex}>
              {rowData &&
                rowData.map((data: any, j: number) => {
                  const tableCellData = createTableBodyRowData({
                    rowData,
                    deck,
                    data,
                    j,
                  });

                  return (
                    <td key={j} className="text-xs font-base text-center">
                      {tableCellData}
                    </td>
                  );
                })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default UnderChartTable;
