import { Link } from "react-router-dom";
import AuctaneLogo from "../../assets/img/auctane-logo-white.svg";
import Menu from "./Menu";
import { useOktaAuth } from "@okta/okta-react";

function Header() {
  const { authState } = useOktaAuth();

  return (
    <>
      {authState?.isAuthenticated && (
        <header className="flex-none h-16 text-white bg-blue-900">
          <div className="container relative flex flex-row items-center justify-between h-full px-8 mx-auto md:px-0">
            <Link
              to="/"
              className="flex flex-row items-center space-x-4 select-none h-1/4"
            >
              <img src={AuctaneLogo} alt="Auctane" className="w-full h-full" />
              <span className="mx-2">|</span>
              <h1 className="text-xl font-light flex-nowrap whitespace-nowrap">
                Harbor
              </h1>
            </Link>
            <div className="relative flex flex-row items-center justify-end space-x-4 select-none">
              <Menu />
            </div>
          </div>
        </header>
      )}
    </>
  );
}

export default Header;
