import React from "react";
import { Link } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";

function Fourzerofour() {
  return (
    <div className="container mx-auto">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center mb-8 space-x-4">
          <Link to="/">
            <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
              <ArrowSmLeftIcon className="text-white" />
            </button>
          </Link>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
      </div>
      <h2 className="mb-2 text-lg font-bold">404 Page not found</h2>
      <p>The page you are looking for could not be found or you do not have permissions to view the page. Please reach out to our IT team to request further assistance.</p>
    </div>
  );
}

export default Fourzerofour;
