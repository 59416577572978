import React from "react";

type LoaderProps = {
  size?: string;
  border?: string;
  color?: string;
};

function Loader({ size, border, color }: LoaderProps) {
  const setSize = () => {
    const sizeMap: any = {
      default: "w-16 h-16",
      small: "w-4 h-4",
    };

    return size ? sizeMap[size] : sizeMap['default']
  };

  const setBorder = () => {
    const borderMap: any = {
      default: "border-4",
      small: "border-2",
    };

    return border ? borderMap[border] : borderMap['default']
  };

  const setColor = () => {
    const colorMap: any = {
      default: "border-blue-900",
      yellow: "border-yellow-500",
    };

    return color ? colorMap[color] : colorMap['default']
  };

  return (
    <div
      style={{ borderTopColor: "transparent" }}
      className={`${setSize()} ${setBorder()} ${setColor()} border-solid rounded-full animate-spin inline-block`}
    ></div>
  );
}

export default Loader;
