import React from "react";
import { useDropzone } from "react-dropzone";

function DragAndDrop({
  onDrop,
  dragText,
  onDragText,
  accept,
}: {
  onDrop: any;
  dragText?: string;
  onDragText?: string;
  accept: string;
}) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  });

  return (
    <div {...getRootProps()} className="h-full">
      <input {...getInputProps()} />
      {isDragActive ? (
        <div className="flex items-center justify-center w-full h-full p-8 text-xl text-center text-gray-500 border border-gray-200 rounded bg-gray-50">
          <div>{onDragText ? onDragText : "Drop the image here ..."}</div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full p-8 text-xl text-center text-gray-500 border border-gray-200 rounded bg-gray-50">
          <div>
            {dragText
              ? dragText
              : "Drag 'n' drop image here, or click to select an image"}
          </div>
        </div>
      )}
    </div>
  );
}

export default DragAndDrop;
