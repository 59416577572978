import React from "react";
import styled from "styled-components";

const TableRow = styled.tr<{ rowStyles: string }>`
  border-spacing: 2px;
  ${(props) => props.rowStyles};
`;

function PrimaryTable({ blockCount, deck }: { blockCount: number; deck: any }) {
  return (
    <div className="flex flex-col gap-4 border border-black p-3">
      <div>{blockCount}</div>
      <h3 className="text-center text-lg">{deck.title}</h3>
      <div className="overflow-x-scroll lg:overflow-hidden w-full">
        <table className="table border border-gray-400 mb-4 w-full leading-6 text-left bg-transparent border-collapse text-neutral-800">
          <thead>
            <tr className="p-1 text-xs font-bold leading-4 align-top border-t border-solid border-collapse border-zinc-200 text-neutral-800">
              <th
                className="border-gray-200 border-r border-l p-1"
                style={{
                  borderRight: "1px solid #808080",
                  borderLeft: "1px solid #808080",
                }}
              ></th>
              {deck?.headers?.map((item: any, index: number) => (
                <th
                  key={index}
                  className={`${
                    deck.plotStyle === "12_MonthsTable" ? "w-16" : "w-20"
                  } p-1 text-xs font-bold leading-4 align-top border-t border-solid border-collapse
                border-zinc-200 text-neutral-800 whitespace-nowrap text-center border-gray-200 border-r border-l`}
                  style={{
                    borderRight: "1px solid #808080",
                    borderLeft: "1px solid #808080",
                  }}
                >
                  <span className="underline">{item}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {deck.rows.map((row: any, rowIndex: number) => {
              var formatMask = row.yScale;
              var precision = 0;
              if (formatMask.includes(".")) {
                precision = formatMask.split(".")[1][0];
              }

              return (
                <TableRow
                  key={rowIndex}
                  rowStyles={row.rowStyle}
                  className="h-8 text-xs leading-4 text-center border-collapse text-gray-800"
                >
                  <>
                    <td
                      className="border-t border-gray-200 border-r border-l p-1 h-8 whitespace-nowrap"
                      style={{
                        borderRight: "1px solid #808080",
                        borderLeft: "1px solid #808080",
                      }}
                    >
                      {row.rowHeader}
                    </td>
                    {deck.headers.map((header: any, cellIndex: number) => {
                      let rowDataValue = row.rowData?.[cellIndex] || " ";

                      if (rowDataValue !== " ") {
                        if (
                          formatMask.includes("MM") ||
                          (rowDataValue >= 1e6 && rowDataValue <= 1e9)
                        ) {
                          rowDataValue =
                            (rowDataValue / 1000000).toFixed(precision) + "M";
                        } else if (
                          formatMask.includes("BB") ||
                          (rowDataValue >= 1e9 && rowDataValue <= 1e12)
                        ) {
                          rowDataValue =
                            (rowDataValue / 1000000000).toFixed(precision) + "B";
                        } else if (
                          formatMask.includes("KK") ||
                          (rowDataValue >= 1e3 && rowDataValue <= 1e6)
                        ) {
                          rowDataValue =
                            (rowDataValue / 1000).toFixed(precision) + "K";
                        } else if (
                          formatMask.includes("%") ||
                          (rowDataValue < 1 && rowDataValue >= -1)
                        ) {
                          rowDataValue =
                            (rowDataValue * 100).toFixed(precision) + "%";
                        } else {
                          rowDataValue = rowDataValue.toFixed(precision);
                        }
                      }

                      return (
                        <td
                          key={cellIndex}
                          className="border-t border-gray-200 text-center border-r border-l p-1 h-8"
                          style={{
                            borderRight: "1px solid #808080",
                            borderLeft: "1px solid #808080",
                          }}
                        >
                          {rowDataValue}
                        </td>
                      );
                    })}
                  </>
                </TableRow>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PrimaryTable;
