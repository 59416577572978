const fetchParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    }
}

const domainRootMapping = {
    shipstation: 'api/airtable',
    shipengine: 'api/airtable/shipengine'
}

const getDomainRoot = (syncType: string) => {
    return (domainRootMapping as any)[syncType];
}

export const getQueueCount = (syncType: string) => {
    return fetch(`${getDomainRoot(syncType)}/count/`, fetchParams).then(data => data.json());
}

export const startSync = (syncType: string) => {
    return fetch(`${getDomainRoot(syncType)}/start/`, fetchParams).then(data => data.json());
}