export interface IButton {
  children: React.ReactNode;
  disabled?: boolean;
  onClick?: any;
}

function Button({ children, disabled, onClick }: IButton) {
  return (
    <button
      className={`px-5 py-2 text-white  rounded-lg  ${
        disabled ? "bg-gray-300 cursor-not-allowed" : "bg-blue-900 hover:bg-blue-800"
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export default Button;
