import React, { Component } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { AuthState, OktaAuth } from "@okta/okta-auth-js";
import { IconTileLink } from "../components/navigation/IconTileLink";
import { UserCircleIcon, DocumentAddIcon, MailIcon, ChartBarIcon } from "@heroicons/react/solid";
import { PermissionsContainer } from "../components/auth/permissionsContainer";
import { airtableSyncAllowList, sendgridAllowList } from "../components/auth/permissionSets";

interface HomeProps {
  oktaAuth: OktaAuth;
  authState: AuthState;
}

export default withOktaAuth(
  class Home extends Component<HomeProps> {
    constructor(props: HomeProps) {
      super(props);
      this.login = this.login.bind(this);
      this.logout = this.logout.bind(this);
    }

    async login() {
      await this.props.oktaAuth.signInWithRedirect();
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      return (
        <div className="App">
          {!this.props.authState?.isAuthenticated && (
            <div>
              <div className="Buttons">
                <button onClick={this.login}>Login</button>
              </div>
            </div>
          )}
          {this.props.authState?.isAuthenticated && (
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
              <IconTileLink href="/profile" title="Update Profile Picture">
                <UserCircleIcon className="w-12 h-12 text-blue-900" />
              </IconTileLink>
              <PermissionsContainer
                email={this.props.authState?.idToken?.claims?.email || ""}
                allowFor={airtableSyncAllowList}
              >
                <IconTileLink href="/airtable-sync" title="KB Article Sync">
                  <DocumentAddIcon className="w-12 h-12 text-blue-900" />
                </IconTileLink>
              </PermissionsContainer>
              <PermissionsContainer
                email={this.props.authState?.idToken?.claims?.email || ""}
                allowFor={sendgridAllowList}
              >
                <IconTileLink href="/sendgrid" title="SendGrid Logs">
                  <MailIcon className="w-12 h-12 text-blue-900" />
                </IconTileLink>
              </PermissionsContainer>
              <IconTileLink href="/wbr" title="WBR Reporting">
                <ChartBarIcon className="w-12 h-12 text-blue-900" />
              </IconTileLink>
            </div>
          )}
        </div>
      );
    }
  }
);
