/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { useOktaAuth } from "@okta/okta-react";
import { getByEventId } from "../../services/sendgrid";
import Loader from "../../components/Loader";

function SendgridId({ match }: { match: any }) {
  const { authState } = useOktaAuth();
  const [data, setData]: [data: any, setData: any] = useState(null);
  const [loaded, setLoaded]: [loaded: any, setLoaded: any] = useState(false);
  const [errorMessage, setErrorMessage]: any = useState(null);
  let history = useHistory();

  useEffect(() => {
    getByEventId({
      eventId: match.params.id,
      authToken: authState?.accessToken?.accessToken,
    })
      .then((response) => {
        if (response.statusCode && response.statusCode !== 200) {
          throw new Error(response.body);
        }
        setData(Object.entries(response.data[0]));
        setLoaded(true);
      })
      .catch((error) => {
        console.error(error.toString().replace(/Error:/g, ""));
        setLoaded(true);
        setErrorMessage(error.toString().replace(/Error:/g, ""));
      });
  }, []);

  const back = () => {
    history.goBack();
  };

  if (loaded) {
    return (
      <div className="flex flex-col gap-8">
        <div className="flex flex-row items-center space-x-4">
          <button
            onClick={back}
            className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800"
          >
            <ArrowSmLeftIcon className="text-white" />
          </button>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
        {errorMessage ? (
          <div className="py-8 text-sm text-center">
            An error occurred, please try again.
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-1">
            <div className="border border-gray-200 rounded shadow">
              <div className="p-4 border-b border-gray-200 shadow-sm bg-gray-50 over">
                <h2 className="text-lg font-medium">Log Details</h2>
              </div>
              <div className="table w-full p-8">
                <div className="table-row-group">
                  {data &&
                    data.map((item: any, index: number) => (
                      <div key={index} className="table-row">
                        <div className="table-cell text-right">
                          <span className="pr-4 font-semibold">{item[0]}:</span>
                        </div>
                        <div className="table-cell">{item[1]}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-full py-36">
        <Loader />
      </div>
    );
  }
}

export default SendgridId;
