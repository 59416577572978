/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { useOktaAuth } from "@okta/okta-react";
import {
  getReportById,
  createHighchartsOptions,
  createHighchartsSubseries,
} from "../../services/wbr";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import InfiniteScroll from "react-infinite-scroller";
import Loader from "../../components/Loader";
import PrimaryTable from "../../components/wbr/PrimaryTable";
import UnderChartTable from "../../components/wbr/UnderChartTable";
import useInterval from "../../hooks/useInterval";

export default function WbrId({ match }: { match: any }) {
  const { authState } = useOktaAuth();
  const [report, setReport]: any = useState(null);
  const [initInterval, setInitInterval] = useState<number | null>(10000);
  const [currentLoad, setCurrentLoad] = useState(30);


  let blockCount = 0;

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  function getReport() {
    getReportById({
      id: match.params.id,
      authToken: authState?.accessToken?.accessToken,
    })
      .then((response) => {
        if (response.statusCode && response.statusCode !== 200) {
          throw new Error(response.body);
        }
        setReport(JSON.parse(response.body).reports);

        blockCount = JSON.parse(response.body).reports.blockStartingNumber || 0;

        if (JSON.parse(response.body).reports.decks) {
          setInitInterval(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    getReport();
  }, []);

  // Initialization interval triggered when sync begins -- ends once queue has been populated
  useInterval(() => {
    getReport();
  }, initInterval);

  return (
    <div className="px-4" style={{
      margin: '1rem calc(50% - 48vw)'
    }}>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center mb-8 space-x-4">
          <Link to="/wbr">
            <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
              <ArrowSmLeftIcon className="text-white" />
            </button>
          </Link>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
        <div></div>
      </div>
      <h2 className="mb-2 text-lg font-bold">Report</h2>
      {report && report.decks ? (
        <div className="flex flex-col gap-8">
          <h3 className="text-2xl text-left font-semibold">
            {report.title} ( Week Ending {report.weekEnding} )
          </h3>
          <InfiniteScroll
            pageStart={0}
            loadMore={() => setCurrentLoad(currentLoad + 50)}
            hasMore={currentLoad <= report.decks?.length}
            loader={
              <div
                key={0}
                className="flex flex-col items-center justify-center w-full py-16 gap-8 font-semibold"
              >
                <Loader />
              </div>
            }
            threshold={4000}
          >
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {report.decks &&
                report.decks
                  .slice(0, currentLoad + 1)
                  .map((deck: any, index: number) => {
                    if (deck.plotStyle === "section") {
                      return (
                        <h3
                          key={index}
                          className="border lg:col-span-2 text-center border-black p-3 text-xl font-semibold"
                        >
                          {deck.title}
                        </h3>
                      );
                    } else {
                      blockCount += 1;

                      if (deck.plotStyle === "6_12_chart") {
                        var subseries: any = [];
                        createHighchartsSubseries({ deck, subseries });

                        const options = createHighchartsOptions({
                          deck,
                          subseries,
                        });

                        return (
                          <div
                            className="flex flex-col gap-4 border border-black p-3"
                            key={index}
                          >
                            <div>{blockCount}</div>
                            <div>
                              <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartComponentRef}
                                updateArgs={[false, false, false]}
                                allowChartUpdate={false}
                              />
                              <div>
                                <UnderChartTable deck={deck} />
                              </div>
                            </div>
                          </div>
                        );
                      } else if (
                        deck.plotStyle === "6_week_table" ||
                        deck.plotStyle === "12_MonthsTable"
                      ) {
                        return (
                          <PrimaryTable
                            blockCount={blockCount}
                            deck={deck}
                            key={index}
                          />
                        );
                      }
                    }
                    return <></>;
                  })}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center w-full py-36 gap-8 font-semibold">
          {report && report.hasFiles ? (
            <>
              <Loader />
              <div>{report.message}</div>
            </>
          ) : report?.message ? (
            report.message
          ) : (
            <Loader />
          )}
        </div>
      )}
    </div>
  );
}
