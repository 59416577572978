const itssUsers = [
  "wesley.maffly-kipp@auctane.com",
  "edward.boswell@auctane.com",
  "robert.banh@auctane.com",
  "chris.collins@auctane.com",
];

export const airtableSyncAllowList = [
  ...itssUsers,
  "sarah.holdgrafer@auctane.com",
  "sarah.wambold@auctane.com",
];

export const sendgridAllowList = [
  ...itssUsers,
  "david.bass@auctane.com",
  "david.cunningham@auctane.com",
  "lachhab.beaoui@auctane.com",
  "robert.hildom@auctane.com",
  "robert.klemme@auctane.com",
  "ryan.pesta@auctane.com",
  "amber.carns@auctane.com",
  "hannah.mcvay@auctane.com",
  "drew.mckey@auctane.com",
  "nestor.vasquez@auctane.com",
  "megan.skorzewski@auctane.com",
  "layne.watkins@auctane.com",
  "lee.cheney@auctane.com",
  "brian.mckeogh@auctane.com",
  "varsha.rajaram@auctane.com",
  "rob.mack@auctane.com",
  "anna.cantlon@auctane.com",
  "ramey.couey@auctane.com",
  "kidist.tesfaye@auctane.com",
  "angie.murphy@auctane.com",
  "josh.martin@auctane.com",
  "ellen.fite@auctane.com",
  "ash.short@auctane.com",
  "kristine.minnicino@auctane.com",
];

export const wbrCreateReportAllowLists = [
  ...itssUsers,
  "brett.schulte@auctane.com",
  "jessee.billman@auctane.com",
  "robert.parnell@auctane.com",
  "jared.wiltshire@auctane.com",
  "javier.mur@auctane.com",
  "matthew.rothstein@auctane.com",
  "sarkis.gharibian@auctane.com",
];
