import { DropdownOption } from "../types";
import { Listbox } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Transition } from "@headlessui/react";
import React from "react";

export interface IDropdownMenu {
    options: DropdownOption[],
    selected: DropdownOption,
    setSelected: any,
    disabled?: boolean
}

export function DropdownMenu({ options, selected, setSelected, disabled }: IDropdownMenu) {
    return (
        <Listbox value={selected} onChange={setSelected} disabled={disabled}>
            <div className="relative">
                <Listbox.Button className="relative w-36 cursor-pointer bg-white py-2 pl-3 text-left text-md border rounded-md shadow-sm">
                    <span className="block truncate">{selected.name}</span>
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronDownIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                    />
                    </span>
                </Listbox.Button>
                <Transition
                    as={React.Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Listbox.Options className="absolute mt-1 w-full overflow-auto rounded-md bg-white border text-left">
                        {options.map((option) => {
                            return (
                                <Listbox.Option 
                                    key={option.id} 
                                    value={option}
                                    className={({ active }) => `relative cursor-pointer select-non py-2 px-3 ${ active ? 'bg-gray-50' : 'text-gray-900'}`}
                                >
                                    {option.name}
                                </Listbox.Option>
                            );
                        })}
                    </Listbox.Options>
                </Transition>
            </div>
        </Listbox>
    )
}