import * as Highcharts from "highcharts";

type WbrGetByReportId = {
  id: string;
  authToken: string | undefined;
};

export const getReportById = async ({ id, authToken }: WbrGetByReportId) => {
  try {
    const response = await fetch(`/api/wbr/reports?id=${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return await response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getAllWbrReports = async (
  { perPage = 10, page = 1, searchText, authToken }: any = {
    authToken: undefined,
  }
) => {
  try {
    const response = await fetch(
      `/api/wbr/reports`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const dataLabelsFormatter = ({
  thisObject,
  deck,
}: {
  thisObject: any;
  deck: any;
}) => {
  const formatMask = deck.yScale;
  let precision = 0;
  if (formatMask.includes(".")) {
    precision = formatMask.split(".")[1][0];
  }
  if (formatMask.includes("MM")) {
    if (thisObject.y === 0) {
      return thisObject.y;
    }
    return Highcharts.numberFormat(thisObject.y / 1000000, precision);
  } else if (formatMask.includes("BB")) {
    if (thisObject.y === 0) {
      return thisObject.y;
    }
    return Highcharts.numberFormat(thisObject.y / 1000000000, precision);
  } else if (formatMask.includes("KK")) {
    if (thisObject.y === 0) {
      return thisObject.y;
    }
    return Highcharts.numberFormat(thisObject.y / 1000, precision);
  } else if (formatMask.includes("%")) {
    if (thisObject.y === 0) {
      return thisObject.y;
    }

    return Highcharts.numberFormat(thisObject.y * 100, precision);
  } else {
    return Highcharts.numberFormat(thisObject.y, precision);
  }
};

export const yAxisLabelFormatter = ({
  thisObject,
  deck,
}: {
  thisObject: any;
  deck: any;
}) => {
  if (deck.yScale?.includes("MM")) {
    if (thisObject.value === 0) {
      return thisObject.value;
    }
    return thisObject.value / 1000000 + "M";
  } else if (deck.yScale?.includes("BB")) {
    if (thisObject.value === 0) {
      return thisObject.value;
    }
    return thisObject.value / 1000000000 + "B";
  } else if (deck.yScale?.includes("KK")) {
    if (thisObject.value === 0) {
      return thisObject.value;
    }
    return thisObject.value / 1000 + "k";
  } else if (deck.yScale?.includes("%")) {
    if (thisObject.value === 0) {
      return thisObject.value;
    }
    if (thisObject.value < 1 && thisObject.value >= 1e-4) {
      return Highcharts.numberFormat(thisObject.value * 100, 0) + "%";
    }
    return thisObject.value + "%";
  } else {
    return thisObject.value;
  }
};

export const createHighchartsOptions = ({
  deck,
  subseries,
}: {
  deck: any;
  subseries: any;
}) => {
  return {
    colors: [
      "#058DC7",
      "#50B432",
      "#ED561B",
      "#DDDF00",
      "#24CBE5",
      "#64E572",
      "#FF9655",
      "#FFF263",
      "#6AF9C4",
    ],
    chart: {
      zoomType: "xy",
    },
    title: {
      text: deck.title,
      align: "center",
    },
    tooltip: {
      enabled: false,
    },
    exporting: { enabled: false },
    xAxis: [
      {
        categories: deck.xAxis,
        crosshair: true,
      },
    ],
    yAxis: [
      {
        // Primary yAxis
        labels: {
          formatter: function (this: any): any {
            return yAxisLabelFormatter({
              thisObject: this,
              deck: deck,
            });
          },
          style: {
            color: "#ED561B",
          },
        },
        title: {
          text: "",
          style: {
            color: "#ED561B",
          },
        },
        opposite: true,
      },
      {
        // Secondary yAxis
        gridLineWidth: 0,
        title: {
          text: "",
          style: {
            color: "#058DC7",
          },
        },
        labels: {
          formatter: function (this: any) {
            return yAxisLabelFormatter({
              thisObject: this,
              deck: deck,
            });
          },
          style: {
            color: "#058DC7",
          },
        },
      },
    ],
    legend: {
      align: "center",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
    },
    series: subseries,
    plotOptions: {
      spline: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              floating: false,
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
              x: 0,
              y: 0,
            },
            yAxis: [
              {
                labels: {
                  align: "right",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                labels: {
                  align: "left",
                  x: 0,
                  y: -6,
                },
                showLastLabel: false,
              },
              {
                visible: false,
              },
            ],
          },
        },
      ],
    },
  };
};

export const createHighchartsSubseries = ({
  deck,
  subseries,
}: {
  deck: any;
  subseries: any;
}) => {
  const markerMap = new Map([
    ["primary", "circle"],
    ["secondary", "square"],
    ["tertiary", "diamond"],
    ["quaternary", "triangle-down"],
  ]);
  const cyColorMap = new Map([
    ["primary", "#3944BC"],
    ["secondary", "#3141f5"],
    ["tertiary", "#6975fa"],
    ["quaternary", "#7c86fc"],
    ["quinary", "#979ffc"],
  ]);
  const pyColorMap = new Map([
    ["primary", "#FFC0CB"],
    ["secondary", "#ffd6dd"],
    ["tertiary", "#fad9df"],
    ["quaternary", "#fae1e5"],
    ["quinary", "#fff0f2"],
  ]);

  deck.yAxis?.forEach(function (seriesData: any) {
    const lineName = seriesData.legendName;
    if (seriesData.lineStyle !== undefined) {
      if (seriesData.lineStyle !== "target") {
        if (deck.axes === 2) {
          subseries.push(
            {
              name: lineName + " - CY",
              type: "spline",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.current[0] &&
                seriesData.metric.current[0].primaryAxis,
              marker: {
                symbol: markerMap.get(seriesData.lineStyle),
              },
              dataLabels: {
                enabled: true,
                formatter: function (this: any): any {
                  return dataLabelsFormatter({
                    thisObject: this,
                    deck: deck,
                  });
                },
              },
              color: cyColorMap.get(seriesData.lineStyle),
            },
            {
              name: lineName + " - CY",
              type: "spline",
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.current[1] &&
                seriesData.metric.current[1].secondaryAxis,
              dataLabels: {
                enabled: true,
                formatter: function (this: any): any {
                  return dataLabelsFormatter({
                    thisObject: this,
                    deck: deck,
                  });
                },
              },
              marker: {
                symbol: markerMap.get(seriesData.lineStyle),
              },
              color: cyColorMap.get(seriesData.lineStyle),
              linkedTo: ":previous",
            },
            {
              name: lineName + " - PY",
              type: "line",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.previous[0] &&
                seriesData.metric.previous[0].primaryAxis,
              marker: {
                enabled: false,
              },
              color: pyColorMap.get(seriesData.lineStyle),
            },
            {
              name: lineName + " - PY",
              type: "line",
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.previous[1] &&
                seriesData.metric.previous[1].secondaryAxis,
              marker: {
                enabled: false,
              },
              color: pyColorMap.get(seriesData.lineStyle),
              linkedTo: ":previous",
            }
          );
        } else if (deck.axes === 1) {
          subseries.push(
            {
              name: lineName + " - CY",
              type: "spline",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.current[0] &&
                seriesData.metric.current[0].primaryAxis,
              marker: {
                symbol: markerMap.get(seriesData.lineStyle),
              },
              dataLabels: {
                enabled: true,
                formatter: function (this: any): any {
                  return dataLabelsFormatter({
                    thisObject: this,
                    deck: deck,
                  });
                },
              },
              color: cyColorMap.get(seriesData.lineStyle),
            },
            {
              name: lineName + " - CY",
              type: "spline",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.current[1] &&
                seriesData.metric.current[1].secondaryAxis,
              dataLabels: {
                enabled: true,
                formatter: function (this: any): any {
                  return dataLabelsFormatter({
                    thisObject: this,
                    deck: deck,
                  });
                },
              },
              marker: {
                symbol: markerMap.get(seriesData.lineStyle),
              },
              color: cyColorMap.get(seriesData.lineStyle),
              linkedTo: ":previous",
            },
            {
              name: lineName + " - PY",
              type: "line",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.previous[0] &&
                seriesData.metric.previous[0].primaryAxis,
              marker: {
                enabled: false,
              },
              color: pyColorMap.get(seriesData.lineStyle),
            },
            {
              name: lineName + " - PY",
              type: "line",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.metric &&
                seriesData.metric.previous[1] &&
                seriesData.metric.previous[1].secondaryAxis,
              marker: {
                enabled: false,
              },
              color: pyColorMap.get(seriesData.lineStyle),
              linkedTo: ":previous",
            }
          );
        }
      } else {
        if (deck.axes === 2) {
          subseries.push(
            {
              name: lineName,
              type: "scatter",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.Target &&
                seriesData.Target.current[0] &&
                seriesData.Target.current[0].primaryAxis,
              tooltip: {
                formatter: function (this: any): any {
                  var string = "week: " + this.value + ", value: " + this.y;
                  return string;
                },
              },
              marker: {
                symbol: "triangle",
              },
              color: "green",
            },
            {
              name: lineName,
              type: "scatter",
              data:
                seriesData &&
                seriesData.Target &&
                seriesData.Target.current[1] &&
                seriesData.Target.current[1].secondaryAxis,
              marker: {
                symbol: "triangle",
              },
              color: "green",
              linkedTo: ":previous",
            }
          );
        } else if (seriesData.axes === 1) {
          subseries.push(
            {
              name: lineName,
              type: "scatter",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.Target &&
                seriesData.Target.current[0] &&
                seriesData.Target.current[0].primaryAxis,
              tooltip: {
                formatter: function (this: any): any {
                  var string = "week: " + this.value + ", value: " + this.y;
                  return string;
                },
              },
              marker: {
                symbol: "triangle",
              },
              color: "green",
            },
            {
              name: lineName,
              type: "scatter",
              yAxis: 1,
              data:
                seriesData &&
                seriesData.Target &&
                seriesData.Target.current[1] &&
                seriesData.Target.current[1].secondaryAxis,
              marker: {
                symbol: "triangle",
              },
              color: "green",
              linkedTo: ":previous",
            }
          );
        }
      }
    }
  });
};

export const createTableBodyRowData = ({
  rowData,
  deck,
  data,
  j,
}: {
  rowData: any;
  deck: any;
  data: any;
  j: number;
}) => {
  let formatMask = deck.yScale;
  let precision = 0;
  let tableCellData;
  tableCellData = data;
  if (formatMask.includes(".")) {
    precision = formatMask.split(".")[1][0];
  }

  if ((j === 1 || j % 2 === 0) && j !== 0) {
    if (tableCellData !== "N/A") {
      tableCellData = Number(tableCellData).toFixed(precision) + "%";
    }
  } else {
    if (formatMask.includes("MM")) {
      if (tableCellData < 1) {
        tableCellData = tableCellData.toFixed(precision);
      } else {
        tableCellData = (tableCellData / 1000000).toFixed(precision);
      }
      if (tableCellData > 0) {
        tableCellData += "M";
      }
    } else if (formatMask.includes("BB")) {
      if (tableCellData < 1) {
        tableCellData = tableCellData.toFixed(precision);
      } else {
        tableCellData = (tableCellData / 1000000000).toFixed(precision);
      }
      if (tableCellData > 0) {
        tableCellData += "B";
      }
    } else if (formatMask.includes("KK")) {
      if (tableCellData < 1) {
        tableCellData = tableCellData.toFixed(precision);
      } else {
        tableCellData = (tableCellData / 1000).toFixed(precision);
      }
      if (tableCellData > 0) {
        tableCellData += "k";
      }
    } else if (formatMask.includes("%")) {
      if (Math.abs(tableCellData) < 1 && Math.abs(tableCellData) >= 1e-3) {
        tableCellData = Math.round(tableCellData * 100) + "%";
      } else {
        tableCellData = Math.round(tableCellData) / 100 + "%";
      }
    } else {
      if (tableCellData < 1) {
        tableCellData = tableCellData.toFixed(precision);
      } else {
        tableCellData = tableCellData.toFixed(precision);
      }
    }
  }

  return tableCellData;
};
