/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import DataTable from "../../components/DataTable";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { useOktaAuth } from "@okta/okta-react";
import { getAllSendgridLogs } from "../../services/sendgrid";
import Loader from "../../components/Loader";

function Sendgrid({ location }: any) {
  const { authState } = useOktaAuth();
  const [search, setSearch] = useState("");
  const [loading, setLoading]: [loading: any, setLoading: any] =
    useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [errorMessage, setErrorMessage]: any = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [tableData, setTableData]: [tableData: any, setTableData: any] =
    useState(null);
  const isInitialMount = useRef(true);
  const perPageOptions = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 },
  ];

  const tableHeader = [
    {
      title: "Seller ID",
      slug: "seller_id",
    },
    {
      title: "Email",
      slug: "email",
    },
    {
      title: "Event",
      slug: "event",
    },
    {
      title: "Timestamp",
      slug: "formattedTimestamp",
    },
  ];

  const [resultsPerPage, setResultsPerPage] = useState(perPageOptions[0]);
  let history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page: number | null = +(params.get("page") as string) || 1;
    const perPage: any =
      +(params.get("perPage") as string) || perPageOptions[0];
    const searchParam: string | null = params.get("search") || "";

    const selectedPerPage: any =
      perPageOptions.find((option: any) => {
        return option.value === perPage;
      }) || perPageOptions[0];

    setCurrentPage(page);
    setResultsPerPage(selectedPerPage);
    setSearch(searchParam);

    if (
      searchParam &&
      selectedPerPage.value === perPageOptions[0].value &&
      page === 1
    ) {
      searchSendgridLogs({
        searchParam: searchParam,
        selectedPerPage: selectedPerPage.value,
        page: page,
      });
    }
  }, []);

  useEffect(() => {
    if (isInitialMount.current || search.length === 0) {
      isInitialMount.current = false;
    } else {
      searchSendgridLogs();
    }
  }, [currentPage]);

  useEffect(() => {
    if (isInitialMount.current || search.length === 0) {
      isInitialMount.current = false;
    } else {
      history.push(
        `/sendgrid?perPage=${
          resultsPerPage.value
        }&page=${currentPage}&search=${encodeURIComponent(search)}`
      );
      searchSendgridLogs();
    }
  }, [resultsPerPage]);

  const searchSendgridLogs = async ({
    searchParam,
    selectedPerPage,
    page,
  }: {
    searchParam?: string;
    selectedPerPage?: number;
    page?: number;
  } = {}) => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const response = await getAllSendgridLogs({
        page: page || currentPage,
        perPage: selectedPerPage || resultsPerPage.value,
        searchText: searchParam || search,
        authToken: authState?.accessToken?.accessToken,
      });

      if (response.statusCode && response.statusCode !== 200)
        throw new Error(response.body);

      if (response.meta?.nextPage) {
        setHasNextPage(true);
      } else {
        setHasNextPage(false);
      }

      const data = response.data.map((item: any) => {
        const formattedTimestamp = format(
          new Date(item.timestamp * 1000),
          "MM/dd/yyyy hh:mm:ss aa"
        );

        return {
          ...item,
          formattedTimestamp,
          link: `/sendgrid/${item.sg_event_id}`,
        };
      });
      setTableData(data);
      setLoading(false);
    } catch (error: any) {
      console.error(error.toString().replace(/Error:/g, ""));
      setLoading(false);
      setErrorMessage(error.toString().replace(/Error:/g, ""));
    }
  };

  const searchLogs = async (event: any) => {
    event.preventDefault();

    if (search.length > 0) {
      history.push(
        `/sendgrid?perPage=${
          resultsPerPage.value
        }&page=${currentPage}&search=${encodeURIComponent(search)}`
      );
      searchSendgridLogs();
    }
  };

  const previousPage = async () => {
    const page = currentPage - 1;
    setCurrentPage(page);
    history.push(
      `/sendgrid?perPage=${
        resultsPerPage.value
      }&page=${page}&search=${encodeURIComponent(search)}`
    );
  };

  const nextPage = async () => {
    const page = currentPage + 1;
    setCurrentPage(page);
    history.push(
      `/sendgrid?perPage=${
        resultsPerPage.value
      }&page=${page}&search=${encodeURIComponent(search)}`
    );
  };

  return (
    <div>
      <div className="flex flex-row items-center mb-8 space-x-4">
        <Link to="/">
          <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
            <ArrowSmLeftIcon className="text-white" />
          </button>
        </Link>
        <span className="text-lg font-bold text-black">Back</span>
      </div>
      <h2 className="mb-2 text-lg font-bold">Sendgrid Logs</h2>
      <div className="flex flex-row items-center justify-between mb-4">
        <form
          onSubmit={searchLogs}
          className="flex flex-row items-center w-1/2 gap-4"
        >
          <Input
            type="search"
            placeholder="Search by Seller Id or Recipient Email"
            setInput={setSearch}
            value={search}
            autoFocus={true}
            maxLength={80}
          />
          <Button>Search</Button>
        </form>
        <div className="flex flex-row items-baseline gap-4">
          <span className="flex-none text-sm text-gray-900">Per Page</span>
          <Select
            items={perPageOptions}
            selectedItem={resultsPerPage}
            setSelected={setResultsPerPage}
          />
        </div>
      </div>
      {tableData && !loading && (
        <>
          {tableData.length === 0 && (
            <div className="py-8 text-sm text-center">No results found</div>
          )}
          {tableData.length > 0 && (
            <>
              <div className="overflow-hidden bg-white rounded-lg shadow">
                <DataTable header={tableHeader} data={tableData}></DataTable>
              </div>
              <div className="flex flex-row items-center justify-between mt-8">
                <Button disabled={currentPage <= 1} onClick={previousPage}>
                  Previous
                </Button>
                <div>Page {currentPage}</div>
                <Button disabled={!hasNextPage} onClick={nextPage}>
                  Next
                </Button>
              </div>
            </>
          )}
        </>
      )}
      {loading && (
        <div className="flex items-center justify-center w-full py-36">
          <Loader />
        </div>
      )}
      {errorMessage && (
        <div className="py-8 text-sm text-center">
          An error occurred, please try again.
        </div>
      )}
    </div>
  );
}

export default Sendgrid;
