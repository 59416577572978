type SendgridLogsRequest = {
  perPage?: number;
  page?: number;
  searchText?: string;
  authToken: string | undefined;
};

type SendgridGetByEventId = {
  eventId: string;
  authToken: string | undefined;
};

export const getAllSendgridLogs = async (
  { perPage = 10, page = 1, searchText, authToken }: SendgridLogsRequest = {
    authToken: undefined,
  }
) => {
  try {
    const response = await fetch(
      `/api/sendgrid?perPage=${perPage}&page=${page}&search=${encodeURIComponent(searchText as string)}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    return response.json();
  } catch (error) {
    console.log(error);
  }
};

export const getByEventId = async ({
  eventId,
  authToken,
}: SendgridGetByEventId) => {
  try {
    const response = await fetch(`/api/sendgrid?id=${eventId}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    return response.json();
  } catch (error) {
    console.log(error);
  }
};
