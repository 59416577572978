import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  withRouter,
  Switch,
} from "react-router-dom";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import Header from "./components/navigation/Header";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Sendgrid from "./routes/sendgrid/Sendgrid";
import SendgridId from "./routes/sendgrid/SendgridId";
import { AirtableSync } from "./routes/airtableSync";
import Wbr from "./routes/wbr/wbr";
import NewWbr from "./routes/wbr/newWbr";
import WbrId from "./routes/wbr/wbrId";
import { PermissionsContainer } from "./components/auth/permissionsContainer";
import { wbrCreateReportAllowLists } from "./components/auth/permissionSets";
import FourZeroFour from "./routes/FourZeroFour";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + "/login/callback",
  scopes: [
    "profile",
    "openid",
    "email",
    "okta.users.read.self",
    "okta.users.manage.self",
  ],
});
class App extends Component {
  restoreOriginalUri: (_oktaAuth: any, originalUri: any) => Promise<void>;
  state: any;

  constructor(props: any) {
    super(props);
    this.restoreOriginalUri = async (
      _oktaAuth: OktaAuth,
      originalUri: string
    ) => {
      props.history.replace(
        toRelativeUrl(originalUri || "/", window.location.origin)
      );
    };
    this.state = {
      authState: null,
    };
  }

  setUserAuthState() {
    oktaAuth.authStateManager.subscribe((authState: any) => {
      this.setState({ authState });
    });
  }

  componentDidMount(): void {
    this.setUserAuthState();
  }

  render() {
    const api_regex = /^\/api\/.*/;
    // if using "/api/" in the pathname, don't use React Router
    if (api_regex.test(window.location.pathname)) {
      return <div />; // must return at least an empty div
    } else {
      // use React Router
      return (
        <div>
          <Security
            oktaAuth={oktaAuth}
            restoreOriginalUri={this.restoreOriginalUri}
          >
            <Header />
            <main className="p-8">
              <div className="container mx-auto">
                <Switch>
                  <SecureRoute path="/" exact={true} component={Home} />
                  <SecureRoute
                    path="/profile"
                    exact={true}
                    component={Profile}
                  />
                  <SecureRoute
                    path="/sendgrid"
                    exact={true}
                    component={Sendgrid}
                  />
                  <SecureRoute
                    exact
                    path="/sendgrid/:id"
                    component={SendgridId}
                  />
                  <SecureRoute path="/airtable-sync" component={AirtableSync} />
                  <SecureRoute exact path="/wbr" component={Wbr}>
                      <Wbr />
                  </SecureRoute>
                  <SecureRoute exact path="/wbr/new">
                    <PermissionsContainer
                      email={this.state.authState?.idToken?.claims?.email || ""}
                      allowFor={wbrCreateReportAllowLists}
                      showErrorRoute
                    >
                      <NewWbr />
                    </PermissionsContainer>
                  </SecureRoute>
                  <SecureRoute
                    exact
                    path="/wbr/reports/:id"
                    component={WbrId}
                  />
                  <Route path="/login/callback" component={LoginCallback} />
                  <SecureRoute path="*">
                    <FourZeroFour />
                  </SecureRoute>
                </Switch>
              </div>
            </main>
          </Security>
        </div>
      );
    }
  }
}

// @ts-ignore
const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
  render() {
    return (
      <Router>
        <AppWithRouterAccess />
      </Router>
    );
  }
}

export default RouterApp;
