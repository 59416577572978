import React from "react";

interface InputProps {
  placeholder?: string;
  type?: string;
  label?: string;
  setInput?: any;
  value?: any;
  autoFocus?: boolean;
  maxLength?: number;
}

function Input({
  placeholder = "",
  type = "text",
  label = "",
  setInput,
  value = "",
  autoFocus = false,
  maxLength
}: InputProps) {
  const onChange = (e: any) => {
    if (setInput) {
      setInput(e.currentTarget.value);
    }
  };

  return (
    <div className="flex flex-col w-full gap-2">
      {label && <label>{label}</label>}
      <div className="relative">
        <input
          type={type}
          className="block w-full text-sm border-gray-300 rounded-md shadow-sm focus:border-blue-800 focus:ring focus:ring-blue-700 focus:ring-opacity-50"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          autoFocus={autoFocus}
          maxLength={maxLength}
        />
      </div>
    </div>
  );
}

export default Input;
