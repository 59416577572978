import React from "react";
import { Link } from "react-router-dom";

interface IProps {
    href: string,
    title: string
}

export const IconTileLink: React.FC<IProps> = ({href, title, children}) => {
    return (
      <Link to={href}>
        <div className="flex flex-col items-center justify-center p-12 space-y-4 text-xl font-semibold text-center text-blue-900 border border-gray-200 rounded-lg bg-gray-50 hover:shadow-lg">
          {children}
          <h2>{title}</h2>
        </div>
      </Link>
    )
}