import React from "react";
import FourZeroFour from "../../routes/FourZeroFour";

export interface IPermissionsContainer {
  allowFor?: string[];
  excludeFor?: string[];
  email: string;
  showErrorRoute?: boolean;
}

export const PermissionsContainer: React.FC<IPermissionsContainer> = ({ children, allowFor, excludeFor, email, showErrorRoute }: any) => {
  // If lists are not included as props, just return true (component defaults to visible)
  const inAllowedList = allowFor ? allowFor.includes(email) : true;
  const notInExcludedList = excludeFor ? !excludeFor.includes(email) : true;

  if (inAllowedList && notInExcludedList) {
    return <div>{children}</div>;
  } else if (showErrorRoute) {
    return <FourZeroFour />;
  } else {
    return null;
  }
};
