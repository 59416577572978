import React, { useCallback, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { v4 as uuidv4 } from "uuid";
import DragAndDrop from "../../components/DragAndDrop";
import Button from "../../components/Button";
import Loader from "../../components/Loader";

export default function NewWbr() {
  const history = useHistory();

  const [uploadYamlFile, setUploadYamlFile]: [
    uploadYamlFile: any,
    setUploadYamlFile: any
  ] = useState(null);
  const [uploadYamlFileError, setUploadYamlFileError] = useState(false);

  const [uploadCsvFile, setUploadCsvFile]: [
    uploadCsvFile: any,
    setUploadCsvlFile: any
  ] = useState(null);

  const [uuid, setUuid]: any = useState();
  const [submitUpload, setSubmitUpload] = useState(false);
  const [submitUploadError, setSubmitUploadError] = useState(false);

  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  const onDropYaml = useCallback((acceptedFiles) => {
    setUploadYamlFileError(false);
    if (acceptedFiles[0]?.name.includes("yaml")) {
      acceptedFiles.forEach((file: any) => {
        setUploadYamlFile({
          name: file.name,
          type: file.type,
          data: file,
        });
      });
    } else {
      setUploadYamlFileError(true);
      setUploadYamlFile(null);
    }
  }, []);

  const onDropCsv = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      setUploadCsvFile({
        name: file.name,
        type: file.type,
        data: file,
      });
    });
  }, []);

  async function getUploadUrls() {
    try {
      // Get the presigned URL
      const response = await fetch(`/api/wbr/upload_url?uuid=${uuid}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
      });

      const jsonResponse = await response.json();

      return JSON.parse(jsonResponse.body);
    } catch (error) {
      console.log(error);
    }
  }

  const onClick = async () => {
    try {
      // Throw error if missing csv and yaml files
      if (!uploadCsvFile || !uploadYamlFile)
        throw new Error("CSV or Yaml is missing");

      setSubmitUpload(true);
      setSubmitUploadError(false);

      const uploadUrls = await getUploadUrls();

      const csvUploadResponse = await fetch(uploadUrls.csvSignedUrl.uploadURL, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: uploadCsvFile.data,
      });
      if (csvUploadResponse.status !== 200)
        throw new Error(JSON.stringify(csvUploadResponse));

      const yamlUploadResponse = await fetch(uploadUrls.yamlSignedUrl.uploadURL, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: uploadYamlFile.data,
      });
      if (yamlUploadResponse.status !== 200)
        throw new Error(JSON.stringify(yamlUploadResponse));

      history.push(`/wbr/reports/${uuid}`);
    } catch (error) {
      console.log(error);
      setSubmitUploadError(true);
      setSubmitUpload(false);
      setUuid(uuidv4()); // Create new UUID to prevent collisions on uploads
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center mb-8 space-x-4">
          <Link to="/wbr">
            <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
              <ArrowSmLeftIcon className="text-white" />
            </button>
          </Link>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
      </div>
      <h2 className="mb-2 text-lg font-bold">New WBR Report</h2>
      {submitUpload && (
        <div className="flex flex-col gap-8 items-center justify-center py-36">
          <Loader />
          <h3 className="font-semibold">
            Uploading files to the server. Do not navigate away from the page.
          </h3>
        </div>
      )}
      {!submitUpload && (
        <>
          <div className="flex flex-col gap-8">
            {submitUploadError && (
              <h3 className="font-semibold text-red-500 text-center">
                There was an error with your upload, please try again.
              </h3>
            )}
            <div className="grid grid-cols-2 gap-8 md:flex-row">
              <div>
                <div className="h-32">
                  <DragAndDrop
                    onDrop={onDropYaml}
                    dragText={
                      uploadYamlFile?.name
                        ? uploadYamlFile?.name
                        : "Click or drag YAML file"
                    }
                    onDragText={
                      uploadYamlFile?.name
                        ? `Drag YAML to box to replace ${uploadYamlFile?.name}`
                        : "Drag YAML to box"
                    }
                    accept=""
                  />
                  {uploadYamlFileError && (
                    <div className="text-sm text-red-500">
                      Please upload .yaml to continue
                    </div>
                  )}
                </div>
              </div>
              <div>
                <DragAndDrop
                  onDrop={onDropCsv}
                  dragText={
                    uploadCsvFile?.name
                      ? uploadCsvFile?.name
                      : "Click or drag CSV file"
                  }
                  onDragText={
                    uploadCsvFile?.name
                      ? `Drag CSV to box to replace ${uploadCsvFile?.name}`
                      : "Drag CSV to box"
                  }
                  accept="text/csv"
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-4">
              <Button
                onClick={onClick}
                disabled={!uploadCsvFile || !uploadYamlFile}
              >
                Upload Files
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
