import { CheckIcon } from '@heroicons/react/solid';
import React from 'react';
import Loader from '../Loader'

export enum Statuses {
    EMPTY,
    IN_PROGRESS,
    TRACKING,
    COMPLETED
}

export interface ISyncStatus {
    status: Statuses
}

export const SyncStatus: React.FC<ISyncStatus> = ({ status }) => {

    const checkClasses = "h-6 w-6 text-green-500 inline-block mb-1 -ml-2";

    switch (Statuses[status]) {
        case 'EMPTY':
            return (
                <div className="h-6">
                    <CheckIcon className={checkClasses} />
                    <span className="ml-1 font-bold">Ready to Sync</span>
                </div>
            );
        case 'IN_PROGRESS':
            return (
                <div className="h-6">
                    <Loader size="small" border="small" color="yellow"/>
                    <span className="ml-2 font-bold">Sync in Progress...</span>
                </div>
            );
        case 'TRACKING':
            return (
                <div className="h-6">
                    <Loader size="small" border="small" color="yellow"/>
                    <span className="ml-2 font-bold">Watching Ongoing Sync...</span>
                </div>
            );
        case 'COMPLETED':
            return (
                <div className="h-6">
                    <CheckIcon className={checkClasses} />
                    <span className="ml-1 font-bold">Sync Complete!</span>
                </div>
            );
        default:
            return <div></div>;
    }
}