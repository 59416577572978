import React, { Component } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon, UserCircleIcon } from "@heroicons/react/solid";
import { withOktaAuth } from "@okta/okta-react";
import { AuthState, OktaAuth } from "@okta/okta-auth-js";

interface HomeProps {
  oktaAuth: OktaAuth;
  authState: AuthState;
}

export default withOktaAuth(
  class DropdownMenu extends Component<HomeProps> {
    constructor(props: HomeProps) {
      super(props);
      this.logout = this.logout.bind(this);
    }

    async logout() {
      await this.props.oktaAuth.signOut();
    }

    render() {
      return (
        <div className="text-right top-16">
          {this.props.authState?.isAuthenticated && (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex items-center justify-center w-full py-2 text-sm font-medium text-white rounded-md md:px-4 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                  <span className="hidden md:block">
                  {this.props.authState?.isAuthenticated
                    ? this.props.authState.idToken?.claims.name
                    : "Auctane Employee"}
                  </span>
                  <UserCircleIcon className="inline w-6 h-6 md:hidden"/>
                  <ChevronDownIcon
                    className="w-5 h-5 ml-2 -mr-1 text-white"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={this.logout}
                          className={`${
                            active ? "bg-gray-100 text-black" : "text-gray-900"
                          } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6 h-6 mr-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                            />
                          </svg>
                          Logout
                        </button>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )}
        </div>
      );
    }
  }
);
