/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect } from "react";
import PictureEditor from "../components/picture/PictureEditor";
import DragAndDrop from "../components/DragAndDrop";
import { useOktaAuth } from "@okta/okta-react";
import { Link } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import Loader from "../components/Loader";

function Profile() {
  const { authState } = useOktaAuth();
  const [imageFile, setImageFile]: [imageFile: any, setImageFile: any] =
    useState(null);
  const [loaded, setLoaded]: [loaded: any, setLoaded: any] = useState(false);
  const [error, setError]: [error: any, setError: any] = useState(false);
  const [currentImageUrl, setCurrentImageUrl]: [
    currentImageUrl: any,
    setCurrentImageUrl: any
  ] = useState(null);

  useEffect(() => {
    const getUserProfile = async () => {
      try {
        const data = await fetch(
          `${process.env.REACT_APP_OKTA_ISSUER}/api/v1/users/me`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${authState?.accessToken?.accessToken}`,
            },
          }
        );
        return data.json();
      } catch (error) {
        setError(true);
        console.log(error);
      }
    };

    //Runs only on the first render
    if (!imageFile) {
      getUserProfile()
        .then((response) => {
          if (response.profile.avatarUrl && response.profile.avatarUrl !== "") {
            // Converts image to base64 for editor
            toDataURL(response.profile.avatarUrl, function (dataUrl: string) {
              setImageFile(dataUrl);
            });
            setCurrentImageUrl(response.profile.avatarUrl);
          }

          setTimeout(() => {
            setLoaded(true);
          }, 250);
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });
    }
  }, []);

  function toDataURL(url: string, callback: any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.send();
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file: any) => {
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const binaryStr = reader.result;

        setImageFile(binaryStr);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const clearImage = () => {
    setImageFile(null);
  };

  if (loaded) {
    return (
      <div>
        <div className="flex flex-row items-center mb-8 space-x-4">
          <Link to="/">
            <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
              <ArrowSmLeftIcon className="text-white" />
            </button>
          </Link>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
        <h2 className="mb-4 text-lg font-bold">
          {!imageFile ? "Upload" : "Edit"} Auctane profile picture
        </h2>
        <div className="flex flex-row space-y-8 md:space-x-8 md:space-y-0">
          {!imageFile && (
            <div
              className="flex flex-col flex-auto w-full"
              style={{ height: "600px" }}
            >
              <DragAndDrop onDrop={onDrop} accept="image/jpeg,image/png"/>
            </div>
          )}
          {imageFile && (
            <PictureEditor
              imageFile={imageFile}
              clearImage={clearImage}
              onDrop={onDrop}
              auth={authState}
              currentImage={currentImageUrl}
            />
          )}
        </div>
      </div>
    );
  }
  return (
    <div className="flex items-center justify-center w-full py-36">
      {!error && <Loader />}
      {error && (
        <h2 className="text-xl font-semibold text-blue-900">
          An Error occurred while loading
        </h2>
      )}
    </div>
  );
}

export default Profile;
