import React, { useEffect, useState } from "react";
import { getQueueCount, startSync } from "../services/airtable";
import useInterval from "../hooks/useInterval";
import { Statuses, SyncStatus } from "../components/airtable/SyncStatus";
import { PageContainer } from "../components/navigation/PageContainer";
import { DropdownMenu } from "../components/DropdownMenu";
import { DropdownOption } from "../types";
import Button from "../components/Button";

const syncOptions: DropdownOption[] = [
    { name: "ShipStation", value: "shipstation", id: 1 },
    { name: "ShipEngine", value: "shipengine", id: 2 },
]

export const AirtableSync: React.FC = () => {
    const [error, setError] = useState<unknown | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [initInterval, setInitInterval] = useState<number | null>(null);
    const [pollingInterval, setPollingInterval] = useState<number | null>(null);
    const [queueLength, setQueueLength] = useState<number>(0);
    const [status, setStatus] = useState<Statuses>(Statuses.EMPTY);
    const [currentSync, setCurrentSync] = useState<DropdownOption>(syncOptions[0]);
    const [dropdownDisabled, setDropdownDisabled] = useState<boolean>(false);

    // Get queue count on initial load
    useEffect(() => {
        const asyncUpdateQueueCount = async () => {
            try {
                const res = await getQueueCount(currentSync.value);
                const queueLength = parseInt(JSON.parse(res.body)?.queueCount);
                setQueueLength(queueLength);

                if (queueLength !== 0) {
                    setStatus(Statuses.TRACKING);
                    setPollingInterval(3000);
                } else {
                    setStatus(Statuses.EMPTY);
                }
                setIsLoaded(true);
            } catch (err) {
                console.log(err)
            }
        }
        asyncUpdateQueueCount().catch(err => setError(err));
    }, [currentSync.value]);

    // Initialization interval triggered when sync begins -- ends once queue has been populated
    useInterval(async () => {
        await updateQueueCount();
        if (queueLength !== 0) {
            setInitInterval(null);
            setDropdownDisabled(false);
            setPollingInterval(3000);
        }
    }, initInterval);

    // Polling interval triggered when queue has been populated
    useInterval(async () => {
        await updateQueueCount();
        if (queueLength === 0) {
            setStatus(Statuses.EMPTY);
            setPollingInterval(null);
        }
    }, pollingInterval);

    // Checks SQS queue and updates state
    const updateQueueCount = async () => {
        try {
            const res = await getQueueCount(currentSync.value);
            const queueLength = parseInt(JSON.parse(res.body)?.queueCount);
            setQueueLength(queueLength);
        } catch (err) {
            console.log(err)
        }
    }

    // Begins sync process if there is not already one ongoing
    const triggerSync = async (e: Event) => {
        try {
            e.preventDefault();

            setStatus(Statuses.IN_PROGRESS);
            setDropdownDisabled(true);

            await updateQueueCount();
            if (queueLength !== 0) {
                setPollingInterval(3000);
                return;
            };

            const res = await startSync(currentSync.value);

            if (res.statusCode === 429) {
                setStatus(Statuses.TRACKING);
            } else {
                setStatus(Statuses.IN_PROGRESS);
            }

            await updateQueueCount();
            setInitInterval(3000);
        } catch (err) {
            console.log(err);
        }
    }

    if (error) {
        return <div>Error: {error}</div>;
    } else if (isLoaded) {
        return (
            <PageContainer title="KB Article Sync">
                <div className="flex flex-col items-center my-5 p-10 bg-gray-50 rounded-lg border text-blue-900">
                    <div className="flex py-5">
                        <p className="my-auto mx-3">Sync articles for:</p>
                        <DropdownMenu 
                            options={syncOptions} 
                            selected={currentSync} 
                            setSelected={setCurrentSync}
                            disabled={dropdownDisabled}
                        />
                    </div>
                    <SyncStatus status={status}></SyncStatus>
                    <p className=" my-5"><strong> KB Articles in Queue: </strong>{queueLength}</p>
                    <Button 
                        disabled={status === Statuses.IN_PROGRESS || status === Statuses.TRACKING} 
                        onClick={triggerSync}
                    >
                        Begin Article Sync
                    </Button>
                </div>
            </PageContainer>
        );
    } else return (
        <div>Loading...</div>
    );
}