import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export function PageContainer({ children, title }: any) {
    return (
        <div>
            <div className="flex flex-row items-center mb-8 space-x-4">
                <Link to="/">
                    <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
                        <ArrowSmLeftIcon className="text-white" />
                    </button>
                </Link>
                <span className="text-lg font-bold text-black">Back</span>
        </div>
        <h2 className="mb-4 text-lg font-bold">{title}</h2>
        {children}
      </div>
    );
}