/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { ArrowSmLeftIcon } from "@heroicons/react/solid";
import Button from "../../components/Button";
import DataTable from "../../components/DataTable";
import Loader from "../../components/Loader";
import { useOktaAuth } from "@okta/okta-react";
import { getAllWbrReports } from "../../services/wbr";
import { format } from "date-fns";
import { PermissionsContainer } from "../../components/auth/permissionsContainer";
import { wbrCreateReportAllowLists } from "../../components/auth/permissionSets";

export default function Wbr({ location }: any) {
  const { authState } = useOktaAuth();
  const [loading, setLoading]: [loading: any, setLoading: any] =
    useState(false);
  const [errorMessage, setErrorMessage]: any = useState(null);
  const [tableData, setTableData]: [tableData: any, setTableData: any] =
    useState(null);

  const tableHeader = [
    {
      title: "ID",
      slug: "Key",
    },
    {
      title: "Date ↓",
      slug: "formattedTimestamp",
    },
  ];

  useEffect(() => {
    searchWbrReports();
  }, []);

  const searchWbrReports = useCallback(async () => {
    try {
      setLoading(true);
      setErrorMessage(null);
      const response = await getAllWbrReports({
        authToken: authState?.accessToken?.accessToken,
      });

      const responseData = JSON.parse(response.body);

      if (response.statusCode && response.statusCode !== 200)
        throw new Error(response.body);

      const data = responseData.reports.Contents.map((item: any) => {
        const formattedTimestamp = format(
          new Date(item.LastModified),
          "MM/dd/yyyy hh:mm:ss aa"
        );

        const formattedKey = item.Key.replace("output/", "").split(".").shift();

        return {
          ...item,
          Key: formattedKey,
          formattedTimestamp,
          link: `/wbr/reports/${formattedKey}`,
        };
      });
      setTableData(data);
      setLoading(false);
    } catch (error: any) {
      console.error(error.toString().replace(/Error:/g, ""));
      setLoading(false);
      setErrorMessage(error.toString().replace(/Error:/g, ""));
    }
  }, []);

  return (
    <div>
      <div className="flex flex-row justify-between">
        <div className="flex flex-row items-center mb-8 space-x-4">
          <Link to="/">
            <button className="w-10 h-10 p-2 bg-blue-900 rounded-full -top-4 -right-4 hover:bg-blue-800">
              <ArrowSmLeftIcon className="text-white" />
            </button>
          </Link>
          <span className="text-lg font-bold text-black">Back</span>
        </div>
        <div>
          <PermissionsContainer
            email={authState?.idToken?.claims?.email || ""}
            allowFor={wbrCreateReportAllowLists}
          >
            <Link to="/wbr/new">
              <Button>New Report</Button>
            </Link>
          </PermissionsContainer>
        </div>
      </div>
      <h2 className="mb-2 text-lg font-bold">WBR Reporting</h2>
      <div className="flex flex-col gap-8">
        {tableData && !loading && (
          <>
            {tableData.length === 0 && (
              <div className="py-8 text-sm text-center">No results found</div>
            )}
            {tableData.length > 0 && (
              <>
                <div className="overflow-hidden bg-white rounded-lg shadow">
                  <DataTable header={tableHeader} data={tableData}></DataTable>
                </div>
              </>
            )}
          </>
        )}
        {loading && (
          <div className="flex items-center justify-center w-full py-36">
            <Loader />
          </div>
        )}
        {errorMessage && (
          <div className="py-8 text-sm text-center">
            An error occurred, please try again.
          </div>
        )}
      </div>
    </div>
  );
}
